import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import * as CONST from "../../components/const";

const kv_bg_h = "../../images/about/kv_bg_h.png";
const kv_bg_v = "../../images/about/kv_bg_v.png";
const cp_bg_h = "../../images/about/cp_bg_h.png";
const cp_bg_v = "../../images/about/cp_bg_v.png";
const history_bg_h = "../../images/about/history_bg_h.png";
const history_bg_v = "../../images/about/history_bg_v.png";
const bg_text = "../../images/about/bg_text.png";
//	const bg_text_sp = "../../images/about/bg_text2.png";
const img6 = "../../images/about/img6.png";
const kv_h = "../../images/about/kv_h_1.png";
const kv_v = "../../images/about/kv_v_1.png";

// const img2 = "../../images/about/img5.png";

function IndexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none
	const TABLE_ROW_COMPANY_CLASS = CONST.TABLE_ROW_CLASS + " xl:w-7/12 border-dotted "
	const TABLE_ROW_CLASS = CONST.TABLE_ROW_CLASS + " xl:w-9/12 2xl:w-8/12 xl:border-none";
	const TABLE_ROW_RIGHT_SPACE_CLASS = "w-full xl:w-3/12 2xl:w-5/12";


	return (
		<Layout>
			<SEO
				description="ALPHA WAVEの企業情報です。トップメッセージ、会社概要、沿革をご覧ください。"
				title="About"
			/>

			<section className={CONST.SECTION_AREA_CLASS}>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.ABOUT_KV_BACK_GROUND_PATTERN_CLASS} src={kv_bg_h} />
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.ABOUT_KV_BACK_GROUND_PATTERN_CLASS} src={kv_bg_v} />
				<div className={CONST.KEY_VISUAL_OFFSET_TITLE_AREA_CLASS}>
					<p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>About us<span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>私たちについて</span></p>
				</div>
			</section>
			<section className={CONST.SECTION_AREA_REVERSE_CLASS + " xl:-mt-32"}>
				<div data-aos="fade-right" className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_LEFT_CLASS + " sm:text-lg sm:font-light sm:pt-14 sm:pb-20 3xl:py-48"}>
					<div>
						<h1 className={CONST.H1_CLASS + " 3xl:text-6xl"}><span className="inline-block">ご挨拶</span></h1>
					</div>
				</div>
				<div className={CONST.KEY_VISUAL_RIGHT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv_h} />
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv_v} />
				</div>
			</section>

			<div className={CONST.PC_SHOW_CLASS}>
				<section className={CONST.SECTION_P_AREA_CLASS + " -my-28"}>
					<div className="w-7/12"></div>
					<div className="w-5/12">
						<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full z-0 xl:ml-24 3xl:ml-32" src={bg_text} />
					</div>
				</section>
			</div>

			{/* <div className=" bg-auto bg-no-repeat h-48 w-full -mt-48  z-0" style={{ backgroundImage: `url(${bg_text})` }} /> */}

			<section data-aos="fade-up" className={CONST.SECTION_P_AREA_CLASS + "xl:-mt-48"}>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS + " my-28"}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="xl:w-8/12 -my-28 xl:my-12 xl:ml-14" src={img6} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
					<p className={CONST.LINE_SPACING_PB_CLASS}>当社は1984年の創立以来、システム開発を主軸としてお客様をICTの側面からご支援する事に努めてまいりました。流行の開発技術を追い求める事は勿論の事、対応可能な業務の範囲もネットワーク、サーバインフラの構築やディレクション業務、システム運用保守、セキュリティコンサルなど徐々に拡大しており、今ではICTにおける様々なご要望にお応えできるようになっております。<br />
						大変喜ばしい事にお客様からはアルファ・ウェーブの技術者だから信頼できる、また一緒にお仕事をしたいと評価を頂く事も多くあります。そこには当社が大切にしている価値観が少なからず寄与しているのではないかと思います。<br />
						その価値観とは、エンジニアとして技術力を高める事に意欲を持ち、社会人としてのセンスを磨き、オフタイムには趣味や色々な方々との交流を満喫し、豊かな人生を送るというものです。
						今後もアルファ・ウェーブに関わる人たちと楽しい時間を共有すると共に、事業を通じて社会の発展に貢献できる企業を目指しますので、より一層のご愛顧を賜れましたら幸甚です。<br />

						<div className="mgr-10"><font size="3">代表取締役社長　奥田史一</font></div></p>
				</div>
				<div className="w-full xl:w-2/12"></div>
			</section>

			{/* 
			
			<section data-aos="fade-up" className={CONST.SECTION_P_AREA_CLASS + " xl:-mt-10"}>
				<div className="w-full xl:w-6/12"></div>
				<div className="w-full xl:w-6/12 z-10">
					<p>社名になっている脳波のアルファ波は、物事に興味を持って集中して取り組んでいるときに見られます。</p>
					<p>これは脳が活性化され良いパフォーマンスを発揮できている状態であると言えます。</p>
					<p>ですから当社の社員には、エンジニアとして技術力を高めることに意欲(or 興味)を持ち、ビジネスマンとしてのセンスを磨き、更にオフタイムには趣味を楽しみ、クラブライフとして様々な経験を共有できる人たちと交流を持つ等、深みのある人生を送って欲しいと願っております。</p>
				</div>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.ABOUT_BACK_GROUND_TEXT_CLASS} src={bg_text_sp} />
			</section>
			<section className={CONST.SECTION_P_AREA_CLASS}>
				<div className="w-full xl:w-10/12 z-10">
					<h2 className={CONST.H2_CLASS}>そしてさらなる高みへ</h2>
					<p className={CONST.LINE_SPACING_PB_CLASS}>コンシューマ向けから大規模開発まで様々なジャンルのシステム開発を手掛けていることは、独立系ソフトウェア開発会社としての強みです。一部ではアジャイル開発も取り入れ、効率の良い開発スタイルを研究するとともに、幅広く技術展開しているノウハウの蓄積によって、お客様のご要望に対して今以上にスピーディーな対応と、質の高い情報サービスの提供を目指します。そのために2009年にネットワーク事業部を立ち上げ、ネットワークとサーバの設計構築、運用、保守業務へと事業拡大を図り、ITソリューションサービスを迅速にワンストップでご提供する環境を整備しました。</p>
					<p className={CONST.LINE_SPACING_PB_CLASS}>当社のネットワーク事業部のエンジニアは、全員プログラミングのスキルを身に付けているので、今後展開が予想される「静的ネットワークから動的ネットワークへ移行」のカギとなる標準技術「OpenFlow」への対応もスムーズに行うことができます。</p>
					<p>このように技術教育事業、システム開発事業、ネットワーク構築事業、すべての事業をリンクさせることで、お客様にとって最適なソリューションをご提供させて頂きます。</p>
				</div>
				<div className="w-full xl:w-2/12"></div>
			</section> */}

			{/* <section className={CONST.SECTION_AREA_REVERSE_CLASS + CONST.PB_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>そしてさらなる高みへ</h2>
						<p className={CONST.LINE_SPACING_PB_CLASS}>コンシューマ向けから大規模開発まで様々なジャンルのシステム開発を手掛けていることは、独立系ソフトウェア開発会社としての強みです。一部ではアジャイル開発も取り入れ、効率の良い開発スタイルを研究するとともに、幅広く技術展開しているノウハウの蓄積によって、お客様のご要望に対して今以上にスピーディーな対応と、質の高い情報サービスの提供を目指します。そのために2009年にネットワーク事業部を立ち上げ、ネットワークとサーバの設計構築、運用、保守業務へと事業拡大を図り、ITソリューションサービスを迅速にワンストップでご提供する環境を整備しました。</p>
						<p className={CONST.LINE_SPACING_PB_CLASS}>当社のネットワーク事業部のエンジニアは、全員プログラミングのスキルを身に付けているので、今後展開が予想される「静的ネットワークから動的ネットワークへ移行」のカギとなる標準技術「OpenFlow」への対応もスムーズに行うことができます。</p>
						<p>このように技術教育事業、システム開発事業、ネットワーク構築事業、すべての事業をリンクさせることで、お客様にとって最適なソリューションをご提供させて頂きます。</p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}><span className="inline-block">そして</span><span className="inline-block">さらなる高みへ</span></h2>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img2} />
				</div>
			</section> */}

			<section className={CONST.SECTION_P_AREA_CLASS + " bg-gray-light"}>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.ABOUT_COMPANY_BACK_GROUND_PATTERN_CLASS} src={cp_bg_h} />
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.ABOUT_COMPANY_BACK_GROUND_PATTERN_CLASS} src={cp_bg_v} />
				<div data-aos="fade-up" className="w-full z-10 ">
					<h2 className={CONST.H2_CLASS + " py-10 xl:py-0 -mb-8 xl:-mb-16"}>会社概要</h2>
				</div>
				<div data-aos="fade-up" className={CONST.SECTION_AREA_CLASS + " mt-10 xl:mt-24 xl:ml-12 "}>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>会社名</div>
						<div className={CONST.TABLE_TEXT_CLASS}>株式会社アルファ・ウェーブ</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>設立</div>
						<div className={CONST.TABLE_TEXT_CLASS}>1984年3月26日</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>資本金</div>
						<div className={CONST.TABLE_TEXT_CLASS}>99,500千円</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>所在地</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>■本社</p>
							<p>〒108-0073　東京都港区三田2-18-5　アルファ・ウェーブビル</p>
							<p className={CONST.LINE_SPACING_PB_CLASS}>TEL:03-5441-5421　FAX:03-5441-2975</p>
							<p>■三田開発センター</p>
							<p>〒108-0073　東京都港区三田4-1-9　三田ヒルサイドビル9階</p>
							<p>TEL:03-5446-9580</p>
							<p>TEL:03-5446-9581（採用直通）　FAX:03-5446-9582</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>売上高</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>1,600 百万円 (2024 年 5 月実績)</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>役員</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>代表取締役社長　奥田史一</p>
							<p>代表取締役　　　河本二郎</p>
							<p>取締役　　　　　中島寿</p>
							<p>取締役　　　　　河本重</p>


						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>従業員数</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>230名 (グループ全体／2024 年 5 月現在)</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>平均年齢</div>
						<div className={CONST.TABLE_TEXT_CLASS}>32.0歳(2024年5月31日現在)</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>事業内容</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>コンピュータソフトウェアの開発事業</p>
							<p>環境（インフラ）構築事業</p>
							<p>情報セキュリティサービス事業</p>
							<p>教育事業</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>関連会社</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>(株)アイランド</p>
							<p>(株)アルケミスト・デザイン</p>
							<p>
								<a href="https://www.horizon-esprit.co.jp/" className="no-underline hover:underline text-blue-500">(株)ホライゾン</a>
							</p>
							<p>(株)メイン・リバー</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>主要取引先</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>株式会社アイネット</p>
							<p>株式会社AXSEED</p>
							<p>株式会社アロバ</p>
							<p>NTTコミュニケーションズ株式会社</p>
							<p>キヤノン株式会社</p>
							<p>キヤノンＩＴソリューションズ株式会社</p>
							<p>株式会社ぐるなび</p>
							<p>高速道路トールテクノロジー株式会社</p>
							<p>株式会社ＣＩＪ</p>
							<p>株式会社ＪＡＬインフォテック</p>
							<p>株式会社ＤＴＳ</p>
							<p>株式会社寺岡精工</p>
							<p>日本ビジネスシステムズ株式会社</p>
							<p>株式会社NEXCOシステムソリューションズ</p>
							<p>富士ソフト株式会社</p>
							<p>株式会社マクロミル</p>
							<p>株式会社YSLソリューション</p>
							<p>（50音順）</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>各種認証・資格</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>労働者派遣事業 [許可番号：派13-308086]</p>
							<p>ISO/IEC 27001:2022 [登録番号：JQA-IM1605]</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>加盟団体</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>一般社団法人　神奈川県情報サービス産業協会(KIA)</p>
							<p>特定非営利活動法人　日本情報技術取引所(JIET)</p>
							<p>一般社団法人　日本シーサート協議会(NCA)</p>
							<p>一般社団法人　東京都情報産業協会(IIT)</p>
						</div>
					</div>
				</div>
				<div className="w-full xl:w-6/12"></div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS}>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.ABOUT_HISTORY_BACK_GROUND_PATTERN_CLASS} src={history_bg_h} />
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.ABOUT_HISTORY_BACK_GROUND_PATTERN_CLASS} src={history_bg_v} />
				<div data-aos="fade-up" className="w-full">
					<h2 className={CONST.H2_CLASS + " py-10 xl:py-0 -mb-8 xl:-mb-16"}>沿革</h2>
					<div className={CONST.SECTION_P_AREA_CLASS + " -ml-5 xs:-ml-6 sm:-ml-10 md:-ml-10 lg:-ml-12 xl:ml-0 -mb-10 xl:-mb-20 3xl:-mb-24"}>
						{/* xs:pb-7 sm:pb-8 md:pb-10 lg:pb-12  */}
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>1984.3</div>
							<div className={CONST.TABLE_TEXT_CLASS}>東京都港区高輪にて株式会社アルファ・ウェーブを設立。資本金を800万円とする</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>1988.9</div>
							<div className={CONST.TABLE_TEXT_CLASS}>業務拡張に伴い、資本金を2,000万円に増資</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>1989.9</div>
							<div className={CONST.TABLE_TEXT_CLASS}>東京都渋谷区神宮前に移転する。業務拡張に伴い、資本金を増資し3,200万円とする</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>1994.5</div>
							<div className={CONST.TABLE_TEXT_CLASS}>自社ビル完成に伴い、本社を東京都港区三田に移転</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>1996.3</div>
							<div className={CONST.TABLE_TEXT_CLASS}>業務拡張に伴い、横浜開発センターを新設</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>1997.2</div>
							<div className={CONST.TABLE_TEXT_CLASS}>業務拡張に伴い、三田開発／研修センターを新設</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2000.8</div>
							<div className={CONST.TABLE_TEXT_CLASS}>業務拡張に伴い、資本金を増資し9,950万円とする</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2002.12</div>
							<div className={CONST.TABLE_TEXT_CLASS}>横浜開発センターを本社に統合</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2004.7</div>
							<div className={CONST.TABLE_TEXT_CLASS}>業務拡張に伴い、三田研修センターを拡大</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2006.7</div>
							<div className={CONST.TABLE_TEXT_CLASS}>代表取締役社長に大指一郎が就任</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2009.12</div>
							<div className={CONST.TABLE_TEXT_CLASS}>ネットワーク事業部を設立</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2010.4</div>
							<div className={CONST.TABLE_TEXT_CLASS}>一般社団法人神奈川県情報サービス産業協会に入会</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2013.6</div>
							<div className={CONST.TABLE_TEXT_CLASS}>一般社団法人神奈川県情報サービス産業協会の理事に大指一郎が就任</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2017.11</div>
							<div className={CONST.TABLE_TEXT_CLASS}>業務拡張に伴い、五反田開発センターを新設</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2018.8</div>
							<div className={CONST.TABLE_TEXT_CLASS}>日本シーサート協議会へ正式加盟</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2019.9</div>
							<div className={CONST.TABLE_TEXT_CLASS}>特定非営利活動法人日本情報技術取引所に入会</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2021.2</div>
							<div className={CONST.TABLE_TEXT_CLASS}>テレワーク促進に伴い、五反田開発センターを三田開発センターに統合</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2022.8</div>
							<div className={CONST.TABLE_TEXT_CLASS}>代表取締役社長に奥田史一が就任</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>
						<div className={TABLE_ROW_CLASS}>
							<div className={CONST.TABLE_TITLE_CLASS}>2024.8</div>
							<div className={CONST.TABLE_TEXT_CLASS}>一般社団法人東京都情報産業協会に入会</div>
						</div>
						<div className={TABLE_ROW_RIGHT_SPACE_CLASS}></div>

						<div className="absolute xl:-top-5 2xl:-top-1 3xl:top-3 -ml-12 mt-32 hidden xl:block">
							{(() => {
								const items = [];
								for (let i = 0; i < 18; i++) {
									items.push(
										<React.Fragment key={i}>
											<div className="h-3 w-3 flex bg-gray-light rounded-full"></div>
											<div className="h-24 w-1 flex bg-gray-light ml-1 -mt-3"></div>
										</React.Fragment>)
								}
								return <React.Fragment>{items}</React.Fragment>;
							})()}
							<span className="h-3 w-3 flex bg-gray-light rounded-full"></span>
						</div>
					</div>
				</div>
			</section>
		</Layout >
	);
}

export default IndexPage;
